import {
  flatMap,
  isNil,
  keyBy,
  mapValues,
  maxBy,
  floor,
  uniqBy
} from 'lodash-es'

import { v4 as uuid } from 'uuid'

import { authStoreFactory } from '@store/Auth'

import {
  type CompetitionBaseDto,
  type CompetitionDetailsDTO,
  type RegistrationUtmInputDTO,
  type ReservedTicketDTO,
  type UserTicketDTO,
  type CompetitionCategoryBaseDto
} from '@elitecompetitions/client-api'

import { hashData } from '@helpers'

import { CurrencyTypeEnum } from '@enums'

import * as SCPixel from '@utils/SCPixel'

export const appsFlyerChannelName = 'AppsflyerAnalytics'

export enum TicketsRemovalApproachTypeEnum {
  SINGULAR = 'Singular',
  MASSIVE = 'Massive'
}

export enum CartTypeEnum {
  BASKET_PAGE = 'BasketPage',
  BASKET_WIDGET = 'BasketWidget'
}

export enum PageTypeEnum {
  BASKET_WIDGET = 'BasketWidget',
  MY_ACCOUNT_MY_TICKETS = 'MyAccountMyTickets',
  POST_CHECKOUT_TRANSACTION_NOT_FOUND_PAGE = 'PostCheckoutTransactionNotFoundPage',
  POST_CHECKOUT_TRANSACTION_NOT_FOUND_PAGE_RECHECK = 'PostCheckoutTransactionNotFoundPageRecheck',
  POST_CHECKOUT_WITH_PRIZES_PAGE = 'PostCheckoutWithPrizesPage',
  POST_CHECKOUT_WITH_PRIZES_PAGE_RECHECK = 'PostCheckoutWithPrizesPageRecheck',
  POST_CHECKOUT_NOT_WON_PAGE = 'PostCheckoutNotWonPage',
  POST_CHECKOUT_NOT_WON_PAGE_RECHECK = 'PostCheckoutNotWonPageRecheck',
  HOME_PAGE = 'HomePage',
  ACTIVE_COMPETITIONS_PAGE = 'ActiveCompetitionsPage',
  COMPETITION_NOT_FOUND_PAGE = 'CompetitionNotFoundPage',
  INSTANT_WIN_COMPETITIONS_PAGE = 'InstantWinCompetitionsPage',
  LIVE_DRAWS_PAGE = 'LiveDrawsPage',
  ACCOUNT_INSTANT_PRIZES_PAGE = 'AccountInstantPrizesPage',
  CATEGORY_PAGE = 'CategoryPage',
  POST_CHECKOUT_TRANSACTION_CONFIRMED = 'PostCheckoutTransactionConfirmed',
  POST_CHECKOUT_ERROR = 'PostCheckoutError'
}

/**
 * @link https://dev.appsflyer.com/hc/docs/in-app-events-ios
 */
export enum AppsFlyerChannelEventNameEnum {
  // Predefined

  AF_COMPLETE_REGISTRATION = 'af_complete_registration',
  AF_LOGIN = 'af_login',
  AF_LIST_VIEW = 'af_list_view',
  AF_CONTENT_VIEW = 'af_content_view',
  AF_INITIATED_CHECKOUT = 'af_initiated_checkout',
  AF_PURCHASE = 'af_purchase',

  // endregion

  // Custom

  RE_ENTER_AGAIN_ADD_TO_CART = 're_enter_again_add_to_cart',
  ORDER_BUMP_ADD_TO_CART = 'order_bump_add_to_cart',
  BASKET_WIDGET_ADD_TO_CART = 'basket_widget_add_to_cart',
  COMPETITION_PAGE_ADD_TO_CART = 'competition_page_add_to_cart',
  IGNORE_COMPETITION = 'ignore_competition',
  REMOVE_FROM_CART = 'remove_from_cart',
  ABORT_CHECKOUT = 'abort_checkout',
  FIRST_PURCHASE = 'first_purchase'

  // endregion
}

/**
 * @link https://dev.appsflyer.com/hc/docs/in-app-events-ios
 */
export enum AppsFlyerChannelEventPropertyNameEnum {
  // region Predefined

  AF_REGISTRATION_METHOD = 'af_registration_method',
  AF_CONTENT_TYPE = 'af_content_type',
  AF_CONTENT_LIST = 'af_content_list',
  AF_PRICE = 'af_price',
  AF_REVENUE = 'af_revenue',
  AF_CONTENT_ID = 'af_content_id',
  AF_CURRENCY = 'af_currency',
  AF_QUANTITY = 'af_quantity',
  AF_ORDER_ID = 'af_order_id',

  // endregion

  // region Custom

  CONTENT_TITLE = 'content_title',
  REGISTRATION_SOURCE = 'registration_source',
  REGISTRATION_CAMPAIGN = 'registration_campaign',
  DISCOUNT_AMOUNT = 'discount_amount',
  CATEGORY_TYPE = 'category_type',
  ORDER_BUMP_DISCOUNT = 'order_bump_discount',
  QUANTITY_CONTENT = 'quantity_content',
  CONTENT_TYPE_PER_COMPETITION = 'content_type_per_competition',
  QUANTITY_PER_COMPETITION = 'quantity_per_competition',
  EXIT_COMPETITION_PAGE = 'exit_competition_page',
  EXIT_PAGE = 'exit_page',
  DESTINATION_PAGE = 'destination_page',
  TICKETS_REMOVAL_APPROACH = 'tickets_removal_approach',
  CART_TYPE = 'cart_type',
  SALE_DISCOUNT_AMOUNT = 'sale_discount_amount',
  ORDER_BUMP_DISCOUNT_PERCENTAGE = 'order_bump_discount_percentage',
  ORDER_BUMP_DISCOUNT_AMOUNT = 'order_bump_discount_amount',
  COUPON_DISCOUNT_AMOUNT = 'coupon_discount_amount',
  WALLET_DISCOUNT_AMOUNT = 'wallet_discount_amount'

  // endregion
}

export interface IAppsFlyerChannelMessage {
  name: AppsFlyerChannelEventNameEnum
  values?: Partial<{
    // region Predefined

    [AppsFlyerChannelEventPropertyNameEnum.AF_REGISTRATION_METHOD]: string
    [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: string
    [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_LIST]: string[]
    [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: number
    [AppsFlyerChannelEventPropertyNameEnum.AF_REVENUE]: number
    [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: string
    [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: string
    [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: number
    [AppsFlyerChannelEventPropertyNameEnum.AF_ORDER_ID]: string

    // endregion

    // region Custom

    [AppsFlyerChannelEventPropertyNameEnum.REGISTRATION_CAMPAIGN]: string
    [AppsFlyerChannelEventPropertyNameEnum.REGISTRATION_SOURCE]: string
    [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]:
      | Record<number, string>
      | string
    [AppsFlyerChannelEventPropertyNameEnum.DISCOUNT_AMOUNT]: string
    [AppsFlyerChannelEventPropertyNameEnum.CATEGORY_TYPE]: string[]
    [AppsFlyerChannelEventPropertyNameEnum.ORDER_BUMP_DISCOUNT]: string
    [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_CONTENT]: number
    [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TYPE_PER_COMPETITION]: Record<
      number,
      string
    >
    [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_PER_COMPETITION]: Record<
      number,
      number
    >
    [AppsFlyerChannelEventPropertyNameEnum.EXIT_COMPETITION_PAGE]: string
    [AppsFlyerChannelEventPropertyNameEnum.TICKETS_REMOVAL_APPROACH]: TicketsRemovalApproachTypeEnum
    [AppsFlyerChannelEventPropertyNameEnum.CART_TYPE]: CartTypeEnum
    [AppsFlyerChannelEventPropertyNameEnum.EXIT_PAGE]: string
    [AppsFlyerChannelEventPropertyNameEnum.DESTINATION_PAGE]: string
    [AppsFlyerChannelEventPropertyNameEnum.ORDER_BUMP_DISCOUNT_AMOUNT]: number
    [AppsFlyerChannelEventPropertyNameEnum.ORDER_BUMP_DISCOUNT_PERCENTAGE]: string
    [AppsFlyerChannelEventPropertyNameEnum.WALLET_DISCOUNT_AMOUNT]: number
    [AppsFlyerChannelEventPropertyNameEnum.SALE_DISCOUNT_AMOUNT]: number
    [AppsFlyerChannelEventPropertyNameEnum.COUPON_DISCOUNT_AMOUNT]: number

    // endregion
  }>
}

const getContentType = (
  competition: CompetitionDetailsDTO | CompetitionBaseDto
) => {
  return Object.keys(competition)
    .filter(key => competition[key] === true)
    .join(', ')
}

const calculateDiscountPercentage = (
  ticketPrice: number,
  saleTicketPrice: number
) => {
  if (ticketPrice <= saleTicketPrice) {
    return '0.00%'
  }

  return `${floor(((ticketPrice - saleTicketPrice) / ticketPrice) * 100, 2)}%`
}

const getTopCategoryBySoldTickets = (
  cartTicketsByCompetition: {
    competition: CompetitionBaseDto
    tickets: ReservedTicketDTO[] | UserTicketDTO[]
  }[]
): CompetitionCategoryBaseDto | null => {
  const { competition } = maxBy(
    cartTicketsByCompetition,
    ({ tickets = [] }) => {
      return tickets.length
    }
  )

  const { categories = [] } = competition

  if (categories.length === 0) {
    return null
  }

  return categories[0]
}

export const sendAppsFlyerChannelMessage = (data: IAppsFlyerChannelMessage) => {
  // console.log('Appsflyer: ', data)

  window[appsFlyerChannelName]?.postMessage(JSON.stringify(data))
}

// region Events

/**
 * @description List view - when the customer see any list of competition
 *             The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerListViewChannelMessage = async ({
  pageType,
  competitions = []
}: {
  pageType: PageTypeEnum
  competitions: Array<
    Partial<CompetitionBaseDto> | Partial<CompetitionDetailsDTO>
  >
}) => {
  if (competitions.length === 0) {
    return
  }

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.PAGE_VIEW, {
      item_ids: competitions.map(competition => String(competition.id)),
      item_category: pageType,
      uuid_c1: uuid(),
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.AF_LIST_VIEW,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: pageType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_LIST]: competitions.map(
        competition => String(competition.id)
      ),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: mapValues(
        keyBy(competitions, ({ id }) => {
          return id
        }),
        ({ name }) => {
          return name
        }
      )
    }
  })
}

/**
 * @description Complete registration - when customer successfully registered
 *             The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerCompleteRegistrationChannelMessage = async ({
  registrationMethod,
  registrationUtm,
  email,
  phoneNumber
}: {
  registrationMethod: string
  registrationUtm?: RegistrationUtmInputDTO
  email?: string
  phoneNumber?: string
}) => {
  let hashedEmail = null
  let hashedPhoneNumber = null

  try {
    hashedEmail = await hashData(email)
    hashedPhoneNumber = await hashData(phoneNumber)
  } catch (error) {
    console.log(error)
  }

  SCPixel.track(SCPixel.SCPixelEventTypeEnum.SIGN_UP, {
    uuid_c1: uuid(),
    sign_up_method: registrationMethod,
    user_email: email,
    user_phone_number: phoneNumber,
    ...(!isNil(hashedEmail)
      ? {
          user_hashed_email: hashedEmail
        }
      : {}),
    ...(!isNil(hashedPhoneNumber)
      ? {
          user_hashed_phone_number: hashedPhoneNumber
        }
      : {})
  })

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.AF_COMPLETE_REGISTRATION,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_REGISTRATION_METHOD]:
        registrationMethod,
      ...(!isNil(registrationUtm)
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.REGISTRATION_CAMPAIGN]:
              registrationUtm.campaign,
            [AppsFlyerChannelEventPropertyNameEnum.REGISTRATION_SOURCE]:
              registrationUtm.source
          }
        : {})
    }
  })
}

/**
 * @description Views specific competition details - when the customer opens the competition details page
 *             The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerViewCompetitionDetailsChannelMessage = async ({
  competition
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice
  const contentType = getContentType(competition)

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.VIEW_CONTENT, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      item_ids: [String(competition.id)],
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(competition.categories.length !== 0
        ? {
            item_category: competition.categories[0].name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.AF_CONTENT_VIEW,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: contentType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      ...(competition.isOnSale
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.DISCOUNT_AMOUNT]:
              calculateDiscountPercentage(
                competition.ticketPrice,
                competition.saleTicketPrice
              )
          }
        : {}),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name,
      [AppsFlyerChannelEventPropertyNameEnum.CATEGORY_TYPE]:
        competition.categories.map(
          category => `${category.name}-${category.id}`
        )
    }
  })
}

/**
 * @description Add to Cart - when customer adds tickets via the enter again functionality
 *             The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerReEnterAgainAddToCartChannelMessage = async ({
  competition,
  quantity
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  quantity: number
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice
  const contentType = getContentType(competition)

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.ADD_CART, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      item_ids: [String(competition.id)],
      number_items: quantity,
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(competition.categories.length !== 0
        ? {
            item_category: competition.categories[0].name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.RE_ENTER_AGAIN_ADD_TO_CART,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: contentType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: quantity,
      ...(competition.isOnSale
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.DISCOUNT_AMOUNT]:
              calculateDiscountPercentage(
                competition.ticketPrice,
                competition.saleTicketPrice
              )
          }
        : {}),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name
    }
  })
}

/**
 * @description Add to Cart - when customer adds tickets via the competition page
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerCompetitionPageAddToCartChannelMessage = async ({
  competition,
  quantity
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  quantity: number
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice
  const contentType = getContentType(competition)

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.ADD_CART, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      item_ids: [String(competition.id)],
      number_items: quantity,
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(competition.categories.length !== 0
        ? {
            item_category: competition.categories[0].name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.COMPETITION_PAGE_ADD_TO_CART,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: contentType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: quantity,
      ...(competition.isOnSale
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.DISCOUNT_AMOUNT]:
              calculateDiscountPercentage(
                competition.ticketPrice,
                competition.saleTicketPrice
              )
          }
        : {}),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name
    }
  })
}

/**
 * @description Add to Cart - when customer adds tickets via the order bump functionality
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerOrderBumpAddToCartChannelMessage = async ({
  competition,
  quantity,
  orderBumpTicketPrice = 0
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  quantity: number
  orderBumpTicketPrice: number
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice
  const contentType = getContentType(competition)

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.ADD_CART, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      item_ids: [String(competition.id)],
      number_items: quantity,
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(competition.categories.length !== 0
        ? {
            item_category: competition.categories[0].name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.ORDER_BUMP_ADD_TO_CART,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: contentType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: quantity,
      [AppsFlyerChannelEventPropertyNameEnum.ORDER_BUMP_DISCOUNT_AMOUNT]: floor(
        competition.ticketPrice * quantity - orderBumpTicketPrice * quantity,
        2
      ),
      [AppsFlyerChannelEventPropertyNameEnum.ORDER_BUMP_DISCOUNT_PERCENTAGE]:
        calculateDiscountPercentage(
          competition.ticketPrice,
          orderBumpTicketPrice
        ),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name
    }
  })
}

/**
 * @description Add to Cart - when customer adds tickets via the basket widget.
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerBasketWidgetAddToCartChannelMessage = async ({
  competition,
  quantity
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  quantity: number
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice
  const contentType = getContentType(competition)

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.ADD_CART, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      item_ids: [String(competition.id)],
      number_items: quantity,
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(competition.categories.length !== 0
        ? {
            item_category: competition.categories[0].name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.BASKET_WIDGET_ADD_TO_CART,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]: contentType,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: quantity,
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name
    }
  })
}

/**
 * @description Purchase - when user completes their purchase
 *             The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerPurchaseChannelMessage = async ({
  isFirstPurchase = false,
  cartTicketsByCompetition = [],
  orderId,
  price
}: {
  isFirstPurchase: boolean
  cartTicketsByCompetition: {
    competition: CompetitionBaseDto
    tickets: UserTicketDTO[]
  }[]
  orderId: string
  price: number
}) => {
  if (cartTicketsByCompetition.length === 0) {
    return
  }

  const uniqueCompetitions = uniqBy(
    cartTicketsByCompetition.map(({ competition }) => {
      return competition
    }),
    ({ id }) => {
      return id
    }
  )

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile)) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    const topCategoryBySoldTickets = getTopCategoryBySoldTickets(
      cartTicketsByCompetition
    )

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.PURCHASE, {
      uuid_c1: uuid(),
      price: price,
      currency: CurrencyTypeEnum.GBP,
      transaction_id: orderId,
      item_ids: uniqueCompetitions.map(({ id }) => {
        return id
      }),
      number_items: flatMap(cartTicketsByCompetition, ({ tickets = [] }) => {
        return tickets
      }).length,
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      ...(!isNil(topCategoryBySoldTickets)
        ? {
            item_category: topCategoryBySoldTickets.name
          }
        : {})
    })
  }

  sendAppsFlyerChannelMessage({
    name: isFirstPurchase
      ? AppsFlyerChannelEventNameEnum.FIRST_PURCHASE
      : AppsFlyerChannelEventNameEnum.AF_PURCHASE,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_REVENUE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: uniqueCompetitions
        .map(({ id }) => {
          return id
        })
        .join(', '),
      ...(uniqueCompetitions.length === 1
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]:
              getContentType(uniqueCompetitions[0])
          }
        : {}),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: flatMap(
        cartTicketsByCompetition,
        ({ tickets }) => {
          return tickets
        }
      ).length,
      [AppsFlyerChannelEventPropertyNameEnum.AF_ORDER_ID]: orderId,
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TYPE_PER_COMPETITION]:
        mapValues(
          keyBy(uniqueCompetitions, ({ id }) => {
            return id
          }),
          getContentType
        ),
      [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_PER_COMPETITION]:
        mapValues(
          keyBy(cartTicketsByCompetition, ({ competition }) => {
            return competition.id
          }),
          ({ tickets }) => {
            return tickets.length
          }
        ),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: mapValues(
        keyBy(uniqueCompetitions, ({ id }) => {
          return id
        }),
        ({ name }) => {
          return name
        }
      ),
      [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_CONTENT]:
        uniqueCompetitions.length
    }
  })
}

/**
 * @description Leaves competition details without adding tickets - when customer navigates from the competition details page without adding to cart any tickets
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerLeavesCompetitionDetailsChannelMessage = ({
  competition,
  exitCompetitionPage
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  exitCompetitionPage: string
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.IGNORE_COMPETITION,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]:
        getContentType(competition),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.CATEGORY_TYPE]:
        competition.categories.map(
          category => `${category.name}-${category.id}`
        ),
      [AppsFlyerChannelEventPropertyNameEnum.EXIT_COMPETITION_PAGE]:
        exitCompetitionPage
    }
  })
}

/**
 * @description Removes from the cart - when user removes the tickets on the basket page or on the basket widget
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerRemoveFromCartChannelMessage = ({
  competition,
  quantity,
  cartType,
  ticketsRemovalApproach
}: {
  competition: CompetitionDetailsDTO | CompetitionBaseDto
  quantity: number
  cartType: CartTypeEnum
  ticketsRemovalApproach: TicketsRemovalApproachTypeEnum
}) => {
  const price = competition.isOnSale
    ? competition.saleTicketPrice
    : competition.ticketPrice

  sendAppsFlyerChannelMessage({
    name: AppsFlyerChannelEventNameEnum.REMOVE_FROM_CART,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: price,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: String(
        competition.id
      ),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]:
        getContentType(competition),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: competition.name,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: quantity,
      [AppsFlyerChannelEventPropertyNameEnum.CART_TYPE]: cartType,
      [AppsFlyerChannelEventPropertyNameEnum.TICKETS_REMOVAL_APPROACH]:
        ticketsRemovalApproach
    }
  })
}

/**
 * @description Initiate checkout - when user clicks the checkout button on the basket page
 *              Abort checkout - when user leaves the checkout procedure without finishing it
 *              The method should be removed, and all the logic should be moved to the mobile application, temporary solution
 */
export const sendAppsFlyerInitiateOrAbortCheckoutChannelMessage = async ({
  cartTicketsByCompetition = [],
  finalPrice,
  salesDiscount,
  couponDiscount,
  walletDiscount,
  isAbortCheckout = false,
  exitPage,
  destinationPage
}: {
  cartTicketsByCompetition: {
    competition: CompetitionBaseDto
    tickets: ReservedTicketDTO[]
  }[]
  finalPrice: number
  couponDiscount: number
  salesDiscount: number
  walletDiscount: number
  isAbortCheckout?: boolean
  exitPage?: string
  destinationPage?: string
}) => {
  if (cartTicketsByCompetition.length === 0) {
    return
  }

  const uniqueCompetitions = uniqBy(
    cartTicketsByCompetition.map(({ competition }) => {
      return competition
    }),
    ({ id }) => {
      return id
    }
  )

  const topCategoryBySoldTickets = getTopCategoryBySoldTickets(
    cartTicketsByCompetition
  )

  const authStore = authStoreFactory()
  const { profile = null } = authStore

  if (!isNil(profile) && !isAbortCheckout) {
    let hashedEmail = null
    let hashedPhoneNumber = null

    try {
      hashedEmail = await hashData(profile.email)
      hashedPhoneNumber = await hashData(profile.phoneNumber)
    } catch (error) {
      console.log(error)
    }

    SCPixel.track(SCPixel.SCPixelEventTypeEnum.START_CHECKOUT, {
      uuid_c1: uuid(),
      price: finalPrice,
      currency: CurrencyTypeEnum.GBP,
      item_ids: uniqueCompetitions.map(({ id }) => {
        return id
      }),
      user_email: profile.email,
      user_phone_number: profile.phoneNumber,
      ...(!isNil(topCategoryBySoldTickets)
        ? {
            item_category: topCategoryBySoldTickets.name
          }
        : {}),
      ...(!isNil(hashedEmail)
        ? {
            user_hashed_email: hashedEmail
          }
        : {}),
      ...(!isNil(hashedPhoneNumber)
        ? {
            user_hashed_phone_number: hashedPhoneNumber
          }
        : {}),
      number_items: flatMap(cartTicketsByCompetition, ({ tickets = [] }) => {
        return tickets
      }).length,
      payment_info_available: 1
    })
  }

  sendAppsFlyerChannelMessage({
    name: isAbortCheckout
      ? AppsFlyerChannelEventNameEnum.ABORT_CHECKOUT
      : AppsFlyerChannelEventNameEnum.AF_INITIATED_CHECKOUT,
    values: {
      [AppsFlyerChannelEventPropertyNameEnum.AF_PRICE]: finalPrice,
      [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_ID]: uniqueCompetitions
        .map(({ id }) => {
          return id
        })
        .join(', '),
      ...(uniqueCompetitions.length === 1
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.AF_CONTENT_TYPE]:
              getContentType(uniqueCompetitions[0])
          }
        : {}),
      [AppsFlyerChannelEventPropertyNameEnum.AF_CURRENCY]: CurrencyTypeEnum.GBP,
      [AppsFlyerChannelEventPropertyNameEnum.AF_QUANTITY]: flatMap(
        cartTicketsByCompetition,
        ({ tickets }) => {
          return tickets
        }
      ).length,
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TYPE_PER_COMPETITION]:
        mapValues(
          keyBy(uniqueCompetitions, ({ id }) => {
            return id
          }),
          getContentType
        ),
      [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_PER_COMPETITION]:
        mapValues(
          keyBy(cartTicketsByCompetition, ({ competition }) => {
            return competition.id
          }),
          ({ tickets }) => {
            return tickets.length
          }
        ),
      [AppsFlyerChannelEventPropertyNameEnum.CONTENT_TITLE]: mapValues(
        keyBy(uniqueCompetitions, ({ id }) => {
          return id
        }),
        ({ name }) => {
          return name
        }
      ),
      [AppsFlyerChannelEventPropertyNameEnum.SALE_DISCOUNT_AMOUNT]:
        salesDiscount,
      [AppsFlyerChannelEventPropertyNameEnum.COUPON_DISCOUNT_AMOUNT]:
        couponDiscount,
      [AppsFlyerChannelEventPropertyNameEnum.WALLET_DISCOUNT_AMOUNT]:
        walletDiscount,
      [AppsFlyerChannelEventPropertyNameEnum.QUANTITY_CONTENT]:
        uniqueCompetitions.length,
      ...(isAbortCheckout
        ? {
            [AppsFlyerChannelEventPropertyNameEnum.EXIT_PAGE]: exitPage,
            [AppsFlyerChannelEventPropertyNameEnum.DESTINATION_PAGE]:
              destinationPage
          }
        : {})
    }
  })
}

// endregion
